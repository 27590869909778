import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Question from '../components/question'
import Footer from '../components/footer'
import Header from '../components/header'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Cyber Tiger</title>
        <meta property="og:title" content="Cyber Tiger" />
      </Helmet>

      <Header/>

      <div className="home-hero">
        <div className="home-hero1">
          <div className="home-container01">
            <h1 className="home-hero-heading heading1">
              Widerstand gegen Cyberkriminalität.
            </h1>
            <span className="home-hero-sub-heading">
              <span>
                Verwalten Sie Ihre Cyberabwehr schnell, einfach &amp; effizient.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                Egal ob Sie ein mikro, kleines oder mittleres Unternehmen haben
              </span>
            </span>
            <div className="home-btn-group">
              <Link to="/register">
                <button className="home-banner-button button orange-cta">
                  Kostenloses Cyber Audit
                </button>
              </Link>
              <Link to="/register">
                <button className="home-hero-button2 button">Erfahren Sie mehr →</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details">
        <div className="home-details1">
          <div className="home-container02">
            <div className="home-stats">
              <div className="home-stat">
                <h1 className="home-text05">70%</h1>
                <span className="home-text06">
                  der Cyberangriffe zielen auf kleine und mittelständische Unternehmen
                </span>
              </div>
              <div className="home-stat1">
                <h1 className="home-text07">63%</h1>
                <span className="home-text08">
                  der kleinen Unternehmen sind mit Ransomware und fortgeschrittenen Bedrohungen konfrontiert
                </span>
              </div>
              <div className="home-stat2">
                <h1 className="home-text09">76%</h1>
                <span className="home-text10">
                  <span>
                    der Angriffe erfolgen nach der Arbeitszeit
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>oder während des Wochenendes</span>
                </span>
              </div>
              <div className="home-stat3">
                <h1 className="home-text14">54%</h1>
                <span className="home-text15">
                  <span>
                    aller Cyberangriffe verursachen

                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>einen finanziellen Schaden von mehr als €500.000</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details2">
        <div className="home-details3">
          <div className="home-container03">
            <h2 className="home-details-heading heading2">
              Sichern Sie Ihr Geschäft und das Ihrer Kunden und Lieferanten
            </h2>
            <span className="home-details-sub-heading">
              Lassen Sie nicht zu, dass Ihr Unternehmen zu einer Statistik wird.
              Mit unserem Cyber Gesundheits Check können Sie beruhigt sein und erhalten 
              einen Fahrplan zur Verbesserung Ihrer Sicherheitslage.
            </span>
          </div>
          <img
            alt="Health Check Preview"
            src="/hc.png"
            className="home-details-image"
          />
        </div>
      </div>
      <div className="home-pricing"></div>
      <div className="home-faq">
        <div className="home-faq-container">
          <div className="home-faq1">
            <div className="home-container04">
              <h2 className="home-text19 heading3">
                <span>Warum unser KONSTENLOSER Cyber</span>
                <br></br>
                <span>Gesundheits Check?</span>
                <br></br>
              </h2>
            </div>
            <div className="home-container05">
              <Question
                Answer="Unser erfahrenes Team wird Ihre Systeme und Prozesse gründlich analysieren und potenzielle Schwachstellen aufdecken, die Hacker ausnutzen."
                Question="Deckt Schwachstellen auf"
              ></Question>
              <Question
                Answer='Angesichts der zunehmenden Besorgnis größerer Unternehmen über Schwachstellen in der Lieferkette (vertraglich als "Netzwerksicherheitshaftung" bezeichnet), die sich auf ihr Geschäft auswirken, bietet unser Bericht die Gewissheit, dass Sie bei der Eindämmung eines potenziellen Cyberangriffs auf Ihr Unternehmen oder das Ihrer Kunden oder Lieferanten proaktiv vorgegangen sind.'
                Question="Sichert den Ruf"
              ></Question>
              <Question
                Answer="In einigen Fällen kann es vorkommen, dass Ihnen ohne einen Cyber Gesundheits Check ein Angebot für eine Cyber Versicherung verweigert wird; unser Check hilft auch bei der Beantwortung der Detailfragen, die von den Versicherern für die Erstellung eines Angebots benötigt werden."
                Question="Zugang zur Cyber Versicherung"
              ></Question>
              <Question
                Answer="Wir erstellen einen unabhängigen, objektiven Bericht über die IT-/Cyber-Situation Ihres Unternehmens, in dem wir potenzielle Probleme aufzeigen, die heute behoben werden müssen, oder wo in den nächsten 12-18-24 Monaten reininvestiert werden sollte."
                Question="Entschärft Bedrohungen"
              ></Question>
              <Question
                Answer="Bleiben Sie konform mit den Branchenvorschriften und vermeiden Sie potenziell schwerwiegende Haftungen von Erst- und Drittparteien, die sich aus der Nichteinhaltung ergeben können."
                Question="Nachweis der Konformität"
              ></Question>
            </div>
          </div>
        </div>
      </div>
      <div className="home-details4">
        <div className="home-details5">
          <div className="home-container06">
            <h2 className="home-details-heading1 heading2">
              Warum Cyber Tiger? 
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </h2>
            <span className="home-details-sub-heading1">
              <span className="home-text24">
                Nahtlos &amp; erschwinglicher Schutz rund um die Uhr
              </span>
              <br className="home-text25"></br>
              <br className="home-text26"></br>
              <span className="home-text27">
                Mit Cyber Tiger können kleine und mittlere Unternehmen jetzt auf
                modernste Technologie und lokale Experten für
                Sicherheit auf Unternehmensniveau zugreifen, die in ihr Budget passen.
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1612521564730-62fc7691cd85?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI0fHxjeWJlcnxlbnwwfHx8fDE3MDE5NTg3NTV8MA&amp;ixlib=rb-4.0.3&amp;w=400"
            className="home-details-image1"
          />
        </div>
      </div>
      <div className="home-banner">
        <div className="home-banner1">
          <h1 className="home-banner-heading heading2">
            Seien Sie proaktiv bei der Abwehr eines möglichen Cyberangriffs
          </h1>
          <span className="home-banner-sub-heading">
            Warten Sie nicht auf einen Angriff - handeln Sie jetzt, bevor es zu spät ist.
          </span>
          <Link to="/register">
          < button className="home-banner-button button">HANDELN SIE JETZT!</button>
          </Link>
        </div>
      </div>

      <Footer/>

    </div>

  )
}

export default Home
