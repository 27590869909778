import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import Footer from '../components/footer'
import Header from '../components/header'

import './home.css'

const Impressum = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Cyber Tiger - Impressum</title>
        <meta property="og:title" content="Cyber Tiger - Impressum" />
      </Helmet>
      
      <Header/>

      <div className="impressum-banner">
          <h1>Impressum</h1>
          <br/>
          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>Magoda Berlin UG (haftungsbeschränkt)<br />
          Schönhauser Allee 104<br />
          10439 Berlin
          </p>

          <p>Handelsregister: HRB 222117 B<br />
          Registergericht: Amtsgericht Berlin-Charlottenburg</p>
          <br/>
          <p><strong>Vertreten durch:</strong><br />
          Emanuel Hoch</p>
          <br/>
          <h2>Kontakt</h2>
          <p>E-Mail: h.emu@hotmail.de</p>
          <br/>
          <h2>Umsatzsteuer-ID</h2>
          <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE335358252</p>
          <br/>
          <h2>EU-Streitschlichtung</h2>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <br/>
          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

      </div>
      
      <Footer/>

    </div>
  )
}

export default Impressum
